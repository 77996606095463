export class SsoConfig {
	isSsoEnabled: boolean;
	clientId: string;
	redirectUri: string;
	issuerUri: string;
	configurationUri: string;
	scope: string;
	clientSecret: string;

	constructor() {
		this.isSsoEnabled = false;
	}
}
