import { AuthConfig } from 'angular-oauth2-oidc';

export let authCodeFlowConfig: AuthConfig = {
	responseType: 'code',
	scope: 'openid',
	timeoutFactor: 0.1,
	checkOrigin: false,
	useSilentRefresh: true,
	requestAccessToken: false,
	disablePKCE: true,
	tokenEndpoint: null,
	silentRefreshRedirectUri: typeof window !== 'undefined' &&  window?.location.origin + '/acasa'
	// silentRefreshRedirectUri: window ?  "" : ""

};
