import { Injectable } from '@angular/core';
import { TokenService } from './token.service';

@Injectable({
	providedIn: 'root',
})
export class DashboardService {
	constructor(private tokenService: TokenService) {}

	getApplicationStatisticsCount(period?) {
		const params = {period};
		return this.tokenService.get('v1/dashboard/applications/statistics/count', {params});
	}

	countTasksByPeriod(status, period?) {
		const params = { period };
		return this.tokenService.get('v1/dashboard/tasks/statistics/count', { params });
	}

	countApplicationsByStatusesMap(period) {
		const params = { period };
		return this.tokenService.get('v1/dashboard/applications/statusesCountMap', { params });
	}
}
