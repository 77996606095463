import { MatPaginatorIntl } from '@angular/material/paginator';

export function CustomPaginator() {
	const customPaginator = new MatPaginatorIntl();

	customPaginator.firstPageLabel = 'Prima pagină';
	customPaginator.itemsPerPageLabel = 'Elemente pe pagină: ';
	customPaginator.lastPageLabel = 'Ultima pagină';
	customPaginator.nextPageLabel = 'Pagina următoare';
	customPaginator.previousPageLabel = 'Pagina anterioară';
	customPaginator.getRangeLabel = (page, pageSize, length) => {
		if (length == 0 || pageSize == 0) {
			return '0 din ' + length;
		}
		length = Math.max(length, 0);
		/** @type {?} */
		var startIndex = page * pageSize;
		// If the start index exceeds the list length, do not try and fix the end index to the end.
		/** @type {?} */
		var endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
		return startIndex + 1 + ' - ' + endIndex + ' din ' + length;
	};
	return customPaginator;
}

export const elementsPerPageList = [
	{ id: 5, number: 5 },
	{ id: 10, number: 10 },
	{ id: 20, number: 20 },
	{ id: 50, number: 50 },
];
