import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { MassRecipientsFilterModel } from '../models/massRecipientsFilterModel';

@Injectable({
	providedIn: 'root',
})
export class MassFilterUsersService {
	constructor(private tokenService: TokenService) {}

	filterUsers(filter: MassRecipientsFilterModel, pageIndex?, pageSize?) {
		if (!pageIndex && !pageSize) {
			return this.tokenService.post('v1/mass/filter/users', filter);
		}
		const params = {pageIndex, pageSize};
		return this.tokenService.post('v1/mass/filter/users', filter, { params: params });
	}

	updateFilters(filter: MassRecipientsFilterModel) {
		return this.tokenService.post('v1/mass/filter/values', filter);
	}

	countUsers(filter: MassRecipientsFilterModel) {
		return this.tokenService.post('v1/mass/filter/users/count', filter);
	}
}
