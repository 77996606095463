import {Injectable} from '@angular/core';
import { TokenService } from './token.service';

@Injectable({
	providedIn: 'root',
})
export class ContactService {

  constructor(private tokenService: TokenService) {
  }

  sendMessage(contact) {
    return this.tokenService.post('v1/contact/message' , contact);
  }

  saveContactDetails(contactDetails) {
    return this.tokenService.post('v1/contact/details' , contactDetails);
  }

  getContactDetails() {
    return this.tokenService.get('v1/contact/details');
  }
}