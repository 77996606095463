import { NativeDateAdapter } from '@angular/material/core';
import moment from 'moment';
import { Injectable } from "@angular/core";

@Injectable()
export class RmpDateformat extends NativeDateAdapter {

	parse(value: any): Date | null {
		if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
			const str = value.split('.');
			const year = Number(str[2]);
			const month = Number(str[1]) - 1;
			const date = Number(str[0]);
			const hour = 12;
			return new Date(year, month, date, hour, 0, 0);
		}
		const timestamp = typeof value === 'number' ? value : Date.parse(value);
		return isNaN(timestamp) ? null : new Date(timestamp);
	}

	format(date: Date, displayFormat: string): string {
		const formatString = 'DD.MM.YYYY';
		return moment(date).format(formatString);
	}
}

export const RMP_DATE_FORMATS = {
	parse: {
		dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
	},
	display: {
		dateInput: 'input',
		monthYearLabel: {year: 'numeric', month: 'numeric'},
		dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
		monthYearA11yLabel: {year: 'numeric', month: 'long'},
	}
};
