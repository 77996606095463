import { AlertNavigationRouteModel } from './alert-navigation-route.model';
export class Alert {
	type: AlertType;
	message: string;
	alertId: string;
	keepAfterRouteChange: boolean;
	duration: number;
	routeForNavigation: AlertNavigationRouteModel;
	isDismissible: boolean;
	isModal: boolean = false;

	constructor(init?: Partial<Alert>) {
		Object.assign(this, init);
	}
}

export enum AlertType {
	Success,
	Error,
	Info,
	Warning,
}
