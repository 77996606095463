import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { User } from '../models/user.model';
import { TokenService } from '../services/token.service';
import { UserService } from '../services/user.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from '../services/auth.service';
import { authCodeFlowConfig } from '../../auth.config';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { SsoConfig } from '../models/ssoConfig.model';
import { UserInfo } from '../models/userInfo.model';
import { LocalStorageEnum } from '../localStorage.enum';
import { CookieService } from 'ngx-cookie-service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class AuthHelper {
	private ssoConfigSubject = new BehaviorSubject<SsoConfig>(new SsoConfig());
	private signatureReminderSubject = new BehaviorSubject<Boolean>(false);
	private timeoutId: any;

	constructor(
		private tokenService: TokenService,
		private authService: AuthService,
		private userService: UserService,
		private oauthService: OAuthService,
		private router: Router,
		private cookieService: CookieService,
		@Inject(PLATFORM_ID) private platformId: Object
	) {}

	public logout() {
		this.clearTimeoutId();
		this.router.navigate(['/acasa']);
		this.authService.logout();
	}

	public initializeOauthConfiguration() {
		this.authService.getSsoConfig().subscribe((response) => {
			if (response?.body?.isSsoEnabled) {
				this.setSsoConfigSubject(response.body);
				this.configureOauthClient(response.body);
				this.trySsoLogin();
			}
		});
	}

	private trySsoLogin() {
		if (isPlatformBrowser(this.platformId)) {
			this.oauthService
				.loadDiscoveryDocumentAndTryLogin()
				.then(() => {})
				.catch((error) => {
					console.error('Error handling callback:', error);
				});
		}
	}

	public loginUsernamePassword(
		user: User,
		successCallback: () => void,
		errorCallback: (err) => void,
		isClerkAuthentication = false
	) {
		if (isClerkAuthentication) {
			this.authService.loginClerk(user).subscribe({
				next: (response) => {
					this.updateTokens(response.headers);
					this.getClerkUserInfo();
					if (response.ok) {
						successCallback();
					}
				},
				error: (error) => {
					errorCallback(error);
				},
			});
		} else {
			this.authService.login(user).subscribe({
				next: (response) => {
					this.updateTokens(response.headers);
					this.getUserInfo();
					if (response.ok) {
						successCallback();
					}
				},
				error: (error) => {
					errorCallback(error);
				},
			});
		}
	}

	public ssoLogin() {
		if (isPlatformBrowser(this.platformId)) {
			this.oauthService.initCodeFlow();
		}
	}

	public getUserInfo() {
		this.userService.getUserInfo().subscribe((res) => {
			this.setUserInfoToLocalStorage(res.body);
		});
	}

	public getClerkUserInfo() {
		this.userService.getClerkUserInfo().subscribe((res) => {
			this.setUserInfoToLocalStorage(res.body);
		});
	}

	private setUserInfoToLocalStorage(userInfo: UserInfo) {
		this.tokenService.setUserInfoAuthData(userInfo);
		if (userInfo?.email) {
			this.cookieService.set('roeid-uid', userInfo.email);
		}
	}

	public checkToken(successCallback?: () => void, errorCallback?: () => void) {
		this.userService.checkToken().subscribe({
			next: () => {
				successCallback();
			},
			error: (erorr) => {
				this.clearTimeoutId();
				errorCallback();
			},
		});
	}

	getUserInfoFromCode(code: string) {
		this.authService.getToken(code).subscribe((response) => {
			this.updateTokens(response.headers);

			this.setUserInfoToLocalStorage(response.body);
			this.setSignatureReminder(response.body?.signatureReminder);
			this.scheduleSsoTokenRefresh();
		});
	}

	refreshSsoToken() {
		const refreshToken = localStorage.getItem(LocalStorageEnum.REFRESH_TOKEN);
		this.authService.refreshToken(refreshToken).subscribe((response) => {
			this.updateTokens(response.headers);
			this.scheduleSsoTokenRefresh();
		});
	}

	private configureOauthClient(ssoConfig: SsoConfig) {
		if (isPlatformBrowser(this.platformId)) {
			this.oauthService.configure({
				...authCodeFlowConfig,
				issuer: ssoConfig.issuerUri,
				clientId: ssoConfig.clientId,
				redirectUri: ssoConfig.redirectUri,
				scope: ssoConfig.scope,
			});
		}
	}

	public scheduleSsoTokenRefresh() {
		clearTimeout(this.timeoutId);
		const timeLeft = this.calculateTimeLeft();
		if (timeLeft != null && timeLeft > 0) {
			this.timeoutId = setTimeout(() => {
				this.refreshSsoToken();
			}, timeLeft);
		}
	}

	private calculateTimeLeft() {
		if (isPlatformBrowser(this.platformId)) {
			const now = new Date();
			const expiryTime = localStorage.getItem(LocalStorageEnum.TOKEN_EXPIRES_AT);
			if (!expiryTime) {
				return null;
			}

			const targetTime = new Date(expiryTime).getTime();
			const oneMinuteBefore = targetTime - 1 * 60 * 1000;
			const timeLeft = oneMinuteBefore - now.getTime();
			return timeLeft;
		}
	}

	public updateTokens(headers: any) {
		this.tokenService.updateToken(headers);
	}

	getSsoConfigSubject(): Observable<SsoConfig> {
		return this.ssoConfigSubject.asObservable();
	}

	private setSsoConfigSubject(ssoConfig: SsoConfig) {
		this.ssoConfigSubject.next(ssoConfig);
	}

	getSignatureReminderSubject(): Observable<Boolean> {
		return this.signatureReminderSubject.asObservable();
	}

	private setSignatureReminder(signatureReminder: Boolean) {
		this.signatureReminderSubject.next(signatureReminder);
	}

	private clearTimeoutId() {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}
	}
}
