import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Alert, AlertType } from '../../shared/models/alert.models';
import { AlertService } from '../../shared/services/alert.service';
import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertNavigationRouteModel } from '../../shared/models/alert-navigation-route.model';

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
	animations: [
		trigger('fade', [
			transition('* => *', [
				query(':enter', style({ opacity: 0 }), { optional: true }),

				query(
					':enter',
					stagger('300ms', [
						animate(
							'.3s ease-in',
							keyframes([
								style({ opacity: 0, transform: 'translateY(-75%) scale(0)', offset: 0 }),
								style({ opacity: 0.5, transform: 'translateY(20px) scale(0.5)', offset: 0.5 }),
								style({ opacity: 1, transform: 'translateY(0) scale(1)', offset: 1.0 }),
							])
						),
					]),
					{ optional: true }
				),
				query(
					':leave',
					stagger('300ms', [
						animate(
							'.3s ease-out',
							keyframes([
								style({ opacity: 1, transform: 'translateY(0) scale(1)', offset: 0 }),
								style({ opacity: 0.5, transform: 'translateY(35px) scale(1.2)', offset: 0.3 }),
								style({ opacity: 0, transform: 'translateY(-75%) scale(0)', offset: 1.0 }),
							])
						),
					]),
					{ optional: true }
				),
			]),
		]),
	],
})
export class AlertComponent implements OnInit, AfterViewChecked {
	@Input() id: string;
	@Input() class: string;
	showCloseButton = true;

	alerts: Alert[] = [];

	constructor(
		private alertService: AlertService,
		private cdRef: ChangeDetectorRef,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {}

	ngOnInit() {
		this.alertService.getAlert(this.id).subscribe((alert: Alert) => {
			if (!alert || !alert.message) {
				// clear alerts when an empty alert is received
				this.alerts = [];
				return;
			}

			// add alert to array
			if (this.alerts.length === 1) {
				this.alerts.shift();
			}
			this.alerts.push(alert);
			this.removeAlertAfterDuration(alert, alert.duration);
		});
	}

	ngAfterViewChecked() {
		this.cdRef.detectChanges();
	}

	private removeAlertAfterDuration(alert: Alert, duration) {
		switch (alert.type) {
			case AlertType.Success:
				if (!alert.isDismissible) {
					setTimeout(() => {
						this.alerts = this.alerts.filter((x) => x !== alert);
					}, duration);
				}
		}
	}

	removeAlert(alert: Alert) {
		this.alerts = this.alerts.filter((x) => x !== alert);
	}

	cssClass(alert: Alert) {
		if (!alert) {
			return;
		}

		// return css class based on alert type
		switch (alert.type) {
			case AlertType.Success: {
				return 'alert-success';
			}
			case AlertType.Error: {
				return 'alert-danger';
			}
			case AlertType.Info: {
				return 'alert-info';
			}
			case AlertType.Warning: {
				return 'alert-warning';
			}
		}
	}

	navigate(alertRoute: AlertNavigationRouteModel, alert: Alert) {
		const query = [];
		this.alerts = this.alerts.filter((x) => x !== alert);
		alertRoute.queryParams.forEach((param) => {
			query[param.param] = param.value;
		});
		if (alertRoute.relativeToThisRoute) {
			this.router.navigate([], {
				relativeTo: this.activatedRoute,
				queryParams: query,
				queryParamsHandling: 'merge',
			});
		} else {
			this.router.navigate([alertRoute.route], { queryParams: query });
		}
	}
}
