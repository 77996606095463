import { Injectable } from '@angular/core';
import { TokenService } from '../../../shared/services/token.service';
import { User } from '../../../shared/models/user.model';
import { IntakeModel } from '../../../shared/models/intake.model';
import { IntakeStatus } from '../../../shared/models/intakeStatus.model';
import { UserStatus } from '../../../shared/models/user-status-enum';
import { LocalStorageEnum } from '../../../shared/localStorage.enum';

@Injectable({
	providedIn: 'root',
})
export class AdminService {
	constructor(private tokenService: TokenService) {}

	getUserById(id: number) {
		return this.tokenService.get('v1/users/' + id);
	}

	deleteUser(id: number) {
		return this.tokenService.delete('v1/admin/user/' + id);
	}

	suspendUser(id: number, isUserNotified, suspensionReason) {
		const params = { notifyUser: isUserNotified };
		return this.tokenService.put('v1/admin/users/' + id + '/suspend', JSON.stringify(suspensionReason), {
			params: params,
		});
	}

	createUser(user: User) {
		return this.tokenService.post('v1/admin/invitation', JSON.stringify(user));
	}

	resendInvitation(userId: number) {
		return this.tokenService.post('v1/admin/invitation/resend/' + userId, null);
	}

	resendEmailConfirmation(userId: number) {
		return this.tokenService.post('v1/admin/emailConfirmation/resend/' + userId, null);
	}

	resendPendingUniversityConfirmationEmail(userId: number) {
		return this.tokenService.post('v1/admin/universityConfirmation/resend/' + userId, null);
	}

	updateUser(user: User) {
		return this.tokenService.put('v1/user', JSON.stringify(user));
	}

	changeUserStatus(id: number, userStatus: UserStatus) {
		const params = { userStatus };
		return this.tokenService.put('v1/admin/users/' + id + '/status', null, { params });
	}

	createIntake(intake: IntakeModel) {
		return this.tokenService.post('v1/intake', JSON.stringify(intake));
	}

	deleteIntake(id: number) {
		return this.tokenService.delete('v1/intake/' + id);
	}

	getIntakes(intakeStatus: IntakeStatus, universityId?) {
		const params = { intakeStatus, universityId };
		return this.tokenService.get('v1/intakes', { params });
	}

	updateIntake(intake: IntakeModel) {
		return this.tokenService.put('v1/intake', JSON.stringify(intake));
	}

	getAllCourses() {
		return this.tokenService.get('v1/courses');
	}

	deleteCourse(id: any) {
		return this.tokenService.delete('v1/course/' + id);
	}

	getCourseDropdownData() {
		return this.tokenService.get('v1/course/levels');
	}

	addCourseImage(formData) {
		const headers = {
			'Access-Control-Expose-Headers': 'authorization',
			'Content-Type': 'multipart/form-data',
		};
		return this.tokenService.post('v1/course/image', formData, { headers: headers });
	}

	getCourseImageById(id: any) {
		return this.tokenService.get('v1/course/' + id + '/image');
	}

	getCoursesByUniversityId(universityId: number) {
		const params = { id: universityId };
		return this.tokenService.get('v1/courses/byUserId', { params });
	}

	createCourseIntake(courseIntake: any) {
		return this.tokenService.post('v1/intake/course', JSON.stringify(courseIntake));
	}

	deleteCourseIntake(courseIntakeId: number) {
		return this.tokenService.delete('v1/courseIntake/' + Number(courseIntakeId));
	}

	editCourseIntake(courseIntakeId, courseIntake: any) {
		delete courseIntake.courseDTO;
		return this.tokenService.put('v1/courseIntake/' + Number(courseIntakeId), courseIntake);
	}

	changeDocumentStatus(id, status, applicationId) {
		const params = { documentStatus: status, applicationId: applicationId };
		return this.tokenService.put('v1/documents/' + id + '/status', null, { params });
	}

	getUserOptionsForAssignedUsers() {
		return this.tokenService.get('v1/admin/users/assignees');
	}

	searchCourseIntakes(
		courseIntakeSearchDTO: { intakeId: number; universityId: number; searchText: string },
		pageIndex,
		pageSize
	) {
		const params = { pageIndex, pageSize };
		return this.tokenService.post('v1/courseIntake/search', courseIntakeSearchDTO, { params });
	}

	saveForm(courseId, formdata) {
		const headers = {
			'Access-Control-Expose-Headers': 'authorization',
			'Content-Type': 'multipart/form-data',
		};
		return this.tokenService.post('v1/course/' + courseId + '/form', formdata, { headers: headers });
	}

	saveApplicationForm(formdata: FormData) {
		const headers = {
			'Access-Control-Expose-Headers': 'authorization',
			'Content-Type': 'multipart/form-data',
		};

		return this.tokenService.post('v1/applicationForm', formdata, { headers: headers });
	}

	deleteApplicationForm(applicationFormFileId) {
		return this.tokenService.delete(`v1/course/applicationForm/${applicationFormFileId}`);
	}

	deleteFormFile(formFileId) {
		return this.tokenService.delete('v1/course/formFile/' + formFileId);
	}

	deleteForm(formId) {
		return this.tokenService.delete('v1/course/form/' + formId);
	}

	downloadFile(formFileId) {
		const jwt = localStorage.getItem(LocalStorageEnum.AUTH_TOKEN);
		const headers = new Headers({
			'Content-Type': 'application/json',
			Authorization: jwt,
		});

		const options = {
			responseType: 'blob',
			headers: headers,
		};
		return this.tokenService.get('v1/course/formFile/' + formFileId, options);
	}
}
