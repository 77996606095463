import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { CourseModel } from '../models/course.model';
import { CourseFiltersModel } from '../models/courseFilters.model';

@Injectable({
	providedIn: 'root',
})
export class CourseService {
	constructor(private tokenService: TokenService) {}

	addCourse(course) {
		const body = JSON.stringify(course);
		return this.tokenService.post('v1/course', body);
	}

	addCourseImage(formData) {
		const headers = {
			'Access-Control-Expose-Headers': 'authorization',
			'Content-Type': 'multipart/form-data',
		};
		return this.tokenService.post('v1/course/image', formData, { headers: headers });
	}

	getCourse(id: number) {
		return this.tokenService.get('v1/course/' + id);
	}

	updateCourse(course: CourseModel) {
		return this.tokenService.put('v1/course', JSON.stringify(course));
	}

	getCourseDropdownData() {
		return this.tokenService.get('v1/course/levels');
	}

	deleteCourseImage(id: number) {
		return this.tokenService.delete('v1/course/' + id + '/image');
	}

	getSubjectAreas(availableCoursesOnly?) {
		const params = { availableCoursesOnly };
		return this.tokenService.get('v1/subjectAreas', { params });
	}

	countCoursesByStatuses(userId) {
		const params = { userId };
		return this.tokenService.get('v1/courses/statuses/count', { params: params });
	}

	getCourseFiltersDropdownValues(filters: any) {
		return this.tokenService.post('v1/courses/filters', JSON.stringify(filters));
	}

	searchCoursesByFilters(filters: CourseFiltersModel) {
		return this.tokenService.post('v1/courses/search', JSON.stringify(filters));
	}

	countCoursesByFilters(filters: CourseFiltersModel) {
		return this.tokenService.post('v1/courses/search/count', JSON.stringify(filters));
	}

	getCourseImage(id: number) {
		return this.tokenService.get('v1/course/' + id + '/image');
	}

	downloadZIP(formId) {
		const headers = new Headers({
			'Content-Type': 'application/json',
		});

		const options = {
			responseType: 'blob',
			headers: headers,
		};
		return this.tokenService.get('v1/course/form/' + formId, options);
	}

	downloadApplicationFormByCourseId(courseId, applicationFormId) {
		const headers = new Headers({
			'Content-Type': 'application/json',
		});

		const options = {
			responseType: 'blob',
			headers: headers,
		};
		return this.tokenService.get(`v1/course/${courseId}/applicationForm/${applicationFormId}`, options);
	}
}
