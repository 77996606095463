import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CandidateService } from '../../../../modules/candidate/services/candidate.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-add-signature-dialog',
	templateUrl: './add-signature-dialog.component.html',
	styleUrls: ['./add-signature-dialog.component.scss'],
})
export class AddSignatureDialogComponent {
	constructor(
		private router: Router,
		private candidateService: CandidateService,
		private dialogRef: MatDialogRef<AddSignatureDialogComponent>
	) {}

	goToEditProfile() {
		this.candidateService.updateSignatureReminder(false).subscribe(() => {});
		this.dialogRef.close();
		this.router.navigate(['/candidate/editProfile']);
	}

	doNotRemind() {
		this.candidateService.updateSignatureReminder(false).subscribe(() => {});
		this.dialogRef.close();
	}
}
