import { Injectable } from '@angular/core';
import { TokenService } from './token.service';

@Injectable({
	providedIn: 'root',
})
export class FaqService {
	constructor(private tokenService: TokenService) {}

	getFaqs() {
		return this.tokenService.get('v1/faqs');
	}

	addFaq(faq) {
		return this.tokenService.post('v1/faqs', JSON.stringify(faq));
	}

	editFaq(faq) {
		return this.tokenService.put('v1/faqs', JSON.stringify(faq));
	}

	deleteFaq(id) {
		return this.tokenService.delete('v1/faqs/' + id);
	}
}
