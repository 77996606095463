import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Alert, AlertType } from '../models/alert.models';
import { NavigationStart, Router } from '@angular/router';
import { AlertNavigationRouteModel } from '../models/alert-navigation-route.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AlertService {
	private subject = new Subject<Alert>();
	private keepAfterRouteChange = false;

	constructor(private router: Router) {
		// clear alert messages on route change unless 'keepAfterRouteChange' flag is true
		router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				if (this.keepAfterRouteChange) {
					// only keep for a single route change
				} else {
					// clear alert messages
					this.clear();
				}
			}
		});
	}

	// subscribe to alerts
	getAlert(alertId?: string): Observable<any> {
		return this.subject.asObservable().pipe(filter((x: Alert) => x && x.alertId === alertId));
	}

	// convenience methods
	success(
		message: string,
		id?: string,
		routeForNavigation?: AlertNavigationRouteModel,
		isDismissible = false,
		keepAfterRouteChange = true,
		duration = 6000
	) {
		this.alert(
			new Alert({
				message,
				type: AlertType.Success,
				alertId: id,
				routeForNavigation: routeForNavigation,
				isDismissible: isDismissible,
				keepAfterRouteChange: keepAfterRouteChange,
				duration: duration,
				isModal: false,
			})
		);
	}

	error(
		message: string,
		id?: string,
		isModal?: boolean,
		routeForNavigation?: AlertNavigationRouteModel,
		isDismissible = true,
		keepAfterRouteChange = true,
		duration = 6000
	) {
		if (isModal !== true) {
			isModal = false;
		}
		this.alert(
			new Alert({
				message,
				type: AlertType.Error,
				alertId: id,
				routeForNavigation: routeForNavigation,
				isDismissible: isDismissible,
				keepAfterRouteChange: keepAfterRouteChange,
				duration: duration,
				isModal: isModal,
			})
		);
	}

	/*info(message: string) {
		this.alert(new Alert({message, type: AlertType.Info}));
	}*/

	/*warn(message: string) {
		this.alert(new Alert({message, type: AlertType.Warning}));
	}*/

	alert(alert: Alert) {
		this.keepAfterRouteChange = alert.keepAfterRouteChange;
		this.subject.next(alert);
	}

	// clear alerts
	clear(alertId?: string) {
		this.subject.next(new Alert({ alertId }));
	}
}
