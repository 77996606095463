import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { TaskModel } from '../models/task.model';
import { TaskFilterModel } from '../models/task-filter.model';
import { TaskStatusEnum } from '../models/task-status.enum';
import { TaskCommentModel } from '../models/task-comment.model';
import { ApplicationsChecklistFilterModel } from '../models/applicationsChecklistFilter.model';
import { TaskApplicationStatusEnum } from '../models/taskApplication-status.enum';

@Injectable({
	providedIn: 'root',
})
export class TaskService {
	constructor(private tokenService: TokenService) {}

	addTask(task: TaskModel) {
		return this.tokenService.post('v1/tasks', task);
	}

	updateTask(task: TaskModel) {
		return this.tokenService.put('v1/tasks', task);
	}

	deleteTask(taskId: number) {
		return this.tokenService.delete('v1/tasks/' + taskId);
	}

	setTaskStatusByTaskId(taskStatus: TaskStatusEnum, taskId: number) {
		const params = { taskStatus };
		return this.tokenService.put('v1/tasks/' + taskId + '/status', null, { params });
	}

	getTaskFilterValues(tasksFilters: TaskFilterModel) {
		return this.tokenService.post('v1/tasks/filters', tasksFilters);
	}

	searchTasksByFilters(taskFilters: TaskFilterModel) {
		return this.tokenService.post('v1/tasks/search', taskFilters);
	}

	searchAllTasksByFilters(taskFilters: TaskFilterModel) {
		return this.tokenService.post('v1/tasks/searchAll', taskFilters);
	}

	searchTasksByKeyword(query: string) {
		const params = { query };
		return this.tokenService.get('v1/tasks/search/keyword', { params });
	}

	getFiltersChecklistApplications(filters: ApplicationsChecklistFilterModel) {
		return this.tokenService.post('v1/checklists/application/filters', filters);
	}

	searchChecklistApplications(filters: ApplicationsChecklistFilterModel) {
		return this.tokenService.post('v1/checklists/application/search', filters);
	}

	searchAllChecklistApplications(filters: ApplicationsChecklistFilterModel) {
		return this.tokenService.post('v1/checklists/application/search/all', filters);
	}

	countChecklistApplications(filters: ApplicationsChecklistFilterModel) {
		return this.tokenService.post('v1/checklists/application/search/count', filters);
	}

	getTaskByTaskId(taskId: number) {
		return this.tokenService.get('v1/tasks/' + taskId);
	}

	getTaskCommentsByTaskId(taskId: number) {
		return this.tokenService.get('v1/tasks/' + taskId + '/comments');
	}

	setTaskApplicationStatus(taskId: number, applicationId: number, status: TaskApplicationStatusEnum) {
		const params = { status };
		return this.tokenService.put('v1/tasks/' + taskId + '/application/' + applicationId, null, { params });
	}

	deleteComment(taskCommentId: number) {
		return this.tokenService.delete('v1/tasks/comment/' + taskCommentId);
	}

	addComment(taskId: number, newComment: TaskCommentModel) {
		return this.tokenService.post('v1/tasks/' + taskId + '/comment', newComment);
	}

	updateComment(comment: TaskCommentModel) {
		return this.tokenService.put('v1/tasks/comment/' + comment.taskCommentId, comment);
	}

	removeTaskApplication(taskId: number, applicationId: number) {
		return this.tokenService.delete('v1/tasks/' + taskId + '/application/' + applicationId);
	}

	moveTaskApplication(taskId: number, newTaskId: number | string, applicationId: any) {
		const params = {
			newTaskId,
			applicationId,
		};
		return this.tokenService.put('v1/tasks/' + taskId + '/application/move', null, { params });
	}

	getTasksByApplicationId(applicationId: any, showMyTasks: boolean) {
		const params = {
			showMyTasks: showMyTasks,
		};
		return this.tokenService.get('v1/tasks/application/' + applicationId, { params });
	}

	countTasksByApplicationId(applicationId: any) {
		return this.tokenService.get('v1/tasks/application/' + applicationId + '/count');
	}

	setTaskArchivedByIds(isArchived, markedIds: number[]) {
		const params = { isArchived };
		return this.tokenService.put('v1/tasks/archive', markedIds, { params });
	}
}
