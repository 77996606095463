<app-alert [class]="'modal-alert'" [id]="'3'"></app-alert>
<div class="modal-content modal-container">
	<mat-dialog-content class="center-content">
		<div class="text-md-center title">Atenție!</div>

		<div class="d-flex justify-content-center flex-column content-container">
			<div class="d-flex">
				<div class="content-description-text">
					Pentru a te bucura de toate funcționalitățile portalului, te rugăm să atașezi semnătura ta în
					profilul contului.
				</div>
			</div>

			<div class="d-flex justify-content-center align-items-center gap-1 content-profile-link">
				<p (click)="goToEditProfile()">Spre profilul tău</p>
				<i class="fa-solid fa-arrow-right"></i>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions class="d-flex justify-content-end gap-md-4 gap-2 mt-32">
		<div class="cancel-button" (click)="doNotRemind()"><p>Cancel</p></div>
	</mat-dialog-actions>
</div>
