<div [@fade]="alerts.length">
	<div *ngFor="let alert of alerts" class="container rmp-alert alert" [ngClass]="cssClass(alert)" role="alert">
		{{ alert.message }}

		<a
			*ngIf="alert.isDismissible"
			class="close float-end ms-5 pointer"
			data-bs-dismiss="alert"
			(click)="removeAlert(alert)"
			>&times;</a
		>
		<ng-container *ngIf="alert.routeForNavigation">
			<a
				(click)="navigate(alert.routeForNavigation, alert)"
				class="alert-link float-md-right d-block text-center d-md-inline"
				>{{ alert.routeForNavigation.name }}</a
			>
		</ng-container>
	</div>
</div>
