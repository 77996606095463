import { Injectable } from '@angular/core';
import { TokenService } from './token.service';

@Injectable({
	providedIn: 'root',
})
export class ApplicationMessagesService {
	constructor(private tokenService: TokenService) {}

	getMessagesByApplicationId(applicationId: number) {
		return this.tokenService.get('v1/applications/' + applicationId + '/messages');
	}

	postMessage(applicationId: number, newMessage: any) {
		return this.tokenService.post('v1/applications/' + applicationId + '/message', JSON.stringify(newMessage));
	}

	markAllAsRead(applicationId: number, userId: number) {
		const params = { isRead: true, userId };
		return this.tokenService.put('v1/application/' + applicationId + '/messages', null, { params });
	}

	markAsRead(applicationMessageId: number, userId) {
		const params = { isRead: true, userId };
		return this.tokenService.put('v1/applicationMessage/' + applicationMessageId, null, { params: params });
	}
}
