import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { MessageModel } from '../models/message.model';
import { MessageStatusEnum } from '../models/messageStatus.enum';

@Injectable({
	providedIn: 'root',
})
export class MassCommunicationService {
	constructor(private tokenService: TokenService) {}

	getMessages(status, pageIndex, pageSize) {
		const params = {
			status,
			pageIndex,
			pageSize,
		};

		return this.tokenService.get('v1/mass/messages', { params });
	}

	updateDraftMessage(mass: MessageModel) {
		return this.tokenService.post('v1/mass/message/draft', mass);
	}

	saveNewDraftMessage(mass: MessageModel) {
		return this.tokenService.post('v1/mass/message/new', mass);
	}

	sendMessage(mass: MessageModel) {
		return this.tokenService.post('v1/mass/message/send', mass);
	}

	countMessages(status: MessageStatusEnum) {
		const params = { status };
		return this.tokenService.get('v1/mass/messages/count', { params });
	}

	deleteMessage(messageId: number) {
		return this.tokenService.delete('v1/mass/message/' + messageId);
	}

	getMessageById(messageId: number) {
		return this.tokenService.get('v1/mass/message/' + messageId);
	}

	countMessagesByQuery(searchQuery: string) {
		const params = { searchQuery };
		return this.tokenService.get('v1/mass/search/messages/count', { params });
	}

	searchMessages(searchQuery: string, pageIndex: number, pageSize: number) {
		const params = {
			searchQuery,
			pageIndex,
			pageSize,
		};
		return this.tokenService.get('v1/mass/search/messages', { params });
	}
}
