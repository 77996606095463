<div class="position-relative">
	<div class="d-flex flex-wrap mb-41">
		<h2 class="mb-md-0 mb-11">Întrebări frecvente</h2>
		<div class="rmp-text-btn rmp-users__btn-right pills">
			<a (click)="openModal(addFaqModal, false)"> <i class="fa fa-plus"></i>Adaugă o întrebare nouă</a>
		</div>
	</div>
	<ng-template #addFaqModal id="addFaqModal" let-c="close" let-d="dismiss">
		<div class="modal-content">
			<app-alert [id]="'1'"></app-alert>
			<div class="modal-body">
				<app-close-button class="rmp-close-modal-btn-mobile" (click)="d('Cross click')"></app-close-button>
				<div class="p-5">
					<h2 class="my-2 text-center">Întrebare nouă</h2>
					<h5 class="text-center rmp-fw-regular">Creează o întrebare nouă.</h5>

					<form class="mt-4" [formGroup]="faqFormGroup">
						<div class="row mb-51">
							<div class="col-12 mb-4">
								<div class="rmp-fg">
									<input
										class="form-control rmp-fg__input"
										type="text"
										formControlName="question"
										placeholder=" "
										[ngClass]="{ 'is-invalid': isFieldRequired('question') }"
									/>
									<label class="rmp-fg__label"> Întrebare </label>
								</div>
								<app-form-field-validation-message
									[displayError]="isFieldRequired('question')"
									[validationMessage]="'Vă rugăm să introduceți o întrebare!'"
								>
								</app-form-field-validation-message>
							</div>
							<div class="col-12">
								<div class="rmp-fg">
									<textarea
										appInputAutoGrow
										class="form-control rmp-fg__input"
										type="text"
										formControlName="answer"
										placeholder=" "
										[ngClass]="{ 'is-invalid': isFieldRequired('answer') }"
									>
									</textarea>
									<label class="rmp-fg__label"> Răspuns </label>
								</div>
								<app-form-field-validation-message
									[displayError]="isFieldRequired('answer')"
									[validationMessage]="'Vă rugăm să introduceți un răspuns!'"
								>
								</app-form-field-validation-message>
							</div>
						</div>
						<div class="col-md-12 col-12 d-flex justify-content-between">
							<div class="d-flex align-items-center">
								<input
									type="checkbox"
									class="me-8"
									id="addExtra"
									(change)="toggleCheckboxAddExtra($event)"
								/>
								<label class="mb-0" for="addExtra">Creează altă întrebare</label>
							</div>
							<button
								type="submit"
								mat-button
								(click)="submitFaq()"
								class="btn btn-primary rmp-button-sm float-end"
							>
								Salvează
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</ng-template>
</div>
<div class="grid">
	<app-contact-faq
		*ngFor="let faq of faqs; let i = index"
		[faq]="faq"
		[index]="i"
		class="card shadow p-5"
	></app-contact-faq>
</div>
