export class Townhall {
	townhallDetailsId: number;
	logo: any;
	description: string;
	county: string;
	city: string;
	typeOfCity: TypeOfCity;
}
export enum TypeOfCity {
	ORAS = 'Orașul',
	COMUNA = 'Comuna',
	MUNICIPIU = 'Municipiul',
	INSTITUTIE = ''
}
