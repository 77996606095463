import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';
import { Globals } from '../globals';

@Injectable({
	providedIn: 'root',
})
export class AuthService {

	constructor(private tokenService: TokenService) {}

	register(user: User, isLead = false, agencyId = null): Observable<any> {
		const params = { isLead, agencyId };
		return this.tokenService.post(Globals.REGISTER_PATH, JSON.stringify(user), { params });
	}

	login(signInData: User): Observable<any> {
		const body = {
			email: signInData.email,
			password: signInData.password,
		};
		return this.tokenService.post(Globals.LOGIN_PATH, body);
	}

	loginClerk(signInData: User): Observable<any> {
		const body = {
			email: signInData.email,
			password: signInData.password,
		};
		return this.tokenService.post(Globals.LOGIN_CLERK_PATH, body);
	}

	logout() {
		localStorage.clear();
		sessionStorage.clear();
		Globals.isLoggedIn = false;
	}

	getToken(code: String): Observable<any> {
		const params = {code}
		return this.tokenService.get('v1/sso/token', {params});
	}

	refreshToken(refreshToken: string) {
		const params = {refreshToken}
		return this.tokenService.get('v1/sso/token/refresh', {params});
	}

	getSsoConfig() {
		return this.tokenService.get('v1/sso/config');
	}
	
}
