import { Injectable } from '@angular/core';
import { TokenService } from './token.service';

@Injectable({
	providedIn: 'root',
})
export class FileUploadService {
	constructor(private tokenService: TokenService) {}

	checkFizeSize(fileSize: number) {
		const params = { size: fileSize };

		return this.tokenService.get('v1/upload/checkSize', { params: params });
	}

	isFileSizeValid(fileSize: number) {
		return fileSize / (1024 * 1024) <= 15;
	}

	isFilePDF(file: File) {
		return file.type === 'application/pdf';
	}
}
