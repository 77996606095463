export enum UserRole {
	SUPERAGENT = 'SUPERAGENT',
	CANDIDATE = 'CANDIDATE',
    CLERK = 'CLERK'
}

export enum UserRoleExtended {
	SUPERAGENT = 'Admin',
	CANDIDATE = 'Utilizator',
    CLERK = 'Funcționar'
}

export namespace UserRoleExtended {
	export function getKeyFromValue(value: string): UserRole {
		let retStatus = null;
		Object.keys(UserRoleExtended).map(key => {
			if (UserRoleExtended[key.valueOf()] === value) {
				retStatus = UserRole[key];
			}
		});
		return retStatus;
	}
}

export const mapUserRoleToRoute = (role: string):  string => {
	switch (role) {
		case UserRole.SUPERAGENT: {
			return "superagent"
		}
		case UserRole.CANDIDATE: {
			return "candidate"
		}
		case UserRole.CLERK: {
			return "functionar"
		}
		default: {
			return null;
		}
	}
}

export const isAdminOrClerk = (role: string): boolean => {
	return role === UserRole.SUPERAGENT || role === UserRole.CLERK
}

export const isAdmin = (role: string): boolean => {
	return role === UserRole.SUPERAGENT;
}