import { TokenService } from '../../../shared/services/token.service';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { LocalStorageEnum } from '../../../shared/localStorage.enum';
import { ApplicationStatusGroupedEnum } from '../../../shared/models/applicationStatus.enum';

@Injectable({
	providedIn: 'root',
})
export class CandidateService {
	constructor(private tokenService: TokenService) {}

	getCandidateProfileByRequest() {
		return this.tokenService.get('v1/candidates/current/profile');
	}

	updateCandidateProfile(candidateProfile) {
		const body = JSON.stringify(candidateProfile);
		return this.tokenService.put('v1/candidates/current/profile', body);
	}

	updateSignatureReminder(signatureReminder: boolean) {
		const params = { signatureReminder };
		return this.tokenService.put('v1/candidates/current/signatureReminder', null, { params });
	}

	getSignatureReminder(){
		return this.tokenService.get('v1/candidates/current/signatureReminder');
	}

	getNationalities() {
		return this.tokenService.get('v1/nationalities');
	}

	checkUserAuthenticated(CandidateProfile) {
		const body = JSON.stringify(CandidateProfile);
		return this.tokenService.post('v1/user/authenticated', body);
	}

	getAllIntakesByCourseIdForDropdown(id) {
		return this.tokenService.get('v1/course/' + id + '/intakes/dropdown');
	}

	createApplication(application) {
		const body = JSON.stringify(application);
		return this.tokenService.post('v1/application', body);
	}

	createApplicationAnonymous(application, token) {
		const body = JSON.stringify(application);
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: token,
			}),
			params: new HttpParams().set('isLead', 'true'),
		};
		return this.tokenService.post('v1/application', body, httpOptions);
	}

	updateApplication(applicationId, application) {
		const body = JSON.stringify(application);
		return this.tokenService.put('v1/applications/' + applicationId + '/intakeInformation', body);
	}

	getRequiredDocumentsForApplication(applicationId) {
		return this.tokenService.get('v1/documents/applications/' + applicationId);
	}

	getApplicationByApplicationId(applicationId) {
		return this.tokenService.get('v1/applications/' + applicationId);
	}

	uploadDocument(formdata) {
		const headers = {
			'Access-Control-Expose-Headers': 'authorization',
			'Content-Type': 'multipart/form-data',
		};
		return this.tokenService.post('v1/documents/application/document', formdata, { headers: headers });
	}

	uploadDocumentFile(formData: FormData) {
		const headers = {
			'Access-Control-Expose-Headers': 'authorization',
			'Content-Type': 'multipart/form-data',
		};
		return this.tokenService.post('v1/documents/application/documentFile', formData, { headers: headers });
	}

	changeApplicationStatus(applicationId, status) {
		const params = { applicationStatus: status };
		return this.tokenService.put('v1/applications/' + applicationId + '/status', null, { params });
	}

	deleteDocumentFile(documentId, fileId?) {
		const params = { fileId };
		return this.tokenService.delete(`v1/documents/${documentId}/files`, { params });
	}

	deleteRequiredDocument(documentId) {
		return this.tokenService.delete('v1/documents/' + documentId);
	}

	requireNewDocument(documentName, applicationId) {
		const params = { documentName };
		return this.tokenService.put('v1/documents/application/' + applicationId + '/require', null, {
			params,
		});
	}

	getMyApplications(status?: ApplicationStatusGroupedEnum) {
		const params = { status };
		return this.tokenService.get('v1/candidate/applications', { params });
	}

	saveSignature(candidateId, signature) {
		let formData = new FormData();
		formData.append('file', signature);
		const headers = {
			'Access-Control-Expose-Headers': 'authorization',
			'Content-Type': 'multipart/form-data',
		};
		return this.tokenService.post('v1/candidates/' + candidateId + '/saveSignature', formData, {
			headers: headers,
		});
	}

	getSignature() {
		return this.tokenService.get('v1/candidates/current/imageSignature');
	}

	getPrefilledForm(id: number) {
		const jwt = localStorage.getItem(LocalStorageEnum.AUTH_TOKEN);
		const headers = new Headers({
			'Content-Type': 'application/json',
			Authorization: jwt,
		});

		const options = {
			responseType: 'json',
			headers: headers,
		};
		return this.tokenService.get(`v1/applications/${id}/prefilled`, options);
	}

	savePDFForm(formData: FormData, isSaveAsDraft = false) {
		const headers = {
			'Access-Control-Expose-Headers': 'authorization',
			'Content-Type': 'multipart/form-data',
		};

		return this.tokenService.post(`v1/application/savePDFForm?isDraft=${isSaveAsDraft}`, formData, {
			headers: headers,
		});
	}

	getApplicationGeneratedForms(applicationId: number) {
		return this.tokenService.get(`v1/application/${applicationId}/generatedForms`);
	}

	deleteApplicationGeneratedForms(deleteIds, applicationId: number) {
		return this.tokenService.delete(`v1/application/${applicationId}/generatedForms?deleteFormsIds=${deleteIds}`);
	}
}
