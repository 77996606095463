import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class DataStorage {

	public storage: any;

	public constructor() {
	}

}
