import { UserFiltersModel } from './../models/user-filters.model';
import { Injectable } from '@angular/core';
import { TokenService } from './token.service';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	constructor(private tokenService: TokenService) {}

	getUserName() {
		const options = { responseType: 'text' };
		return this.tokenService.get('v1/users/current/name', options);
	}

	getUserById(id) {
		return this.tokenService.get('v1/users/' + id);
	}

	getUserInfo() {
		return this.tokenService.get('v1/users/current/user-data');
	}

	getClerkUserInfo(){
		return this.tokenService.get('v1/users/current/clerk/user-data');
	}

	setUserAfterLoginRoute(route) {
		const body = {
			afterLoginRoute: route,
		};
		return this.tokenService.put('v1/users/current/afterLoginRoute', body);
	}

	getUserNameAndEmailByIds(userIds: number[]) {
		const params = { userIds };
		return this.tokenService.get('v1/users/name-email', { params });
	}

	getUserFilterValues(filters: any) {
		return this.tokenService.post('v1/users/filters', JSON.stringify(filters));
	}

	countUsersByFilters(filters: UserFiltersModel) {
		return this.tokenService.post('v1/users/search/count', JSON.stringify(filters));
	}

	searchUsersByFilters(filters: UserFiltersModel) {
		return this.tokenService.post('v1/users/search', JSON.stringify(filters));
	}

	countUsersByRoleAndStatus() {
		return this.tokenService.get('v1/users/rolesAndStatuses/count');
	}

	getCountries() {
		return this.tokenService.get('v1/countries');
	}

	checkToken() {
		return this.tokenService.get('v1/check/token');
	}

	setupPassword(password: String) {
		return this.tokenService.post('v1/setupPassword', password);
	}

	recoverPassword(email: string) {
		return this.tokenService.post('v1/email/send/recoverPassword?email=' + email, null);
	}

	resendEmailConfirmation(email: string) {
		const params = { email };
		return this.tokenService.post('v1/user/resendConfirmation', null, { params });
	}

	isCandidateVerified() {
		return this.tokenService.get('v1/candidates/current/verified');
	}

	verifyIDCard(idCard) {
		return this.tokenService.post('v1/candidates/card/verify', JSON.stringify(idCard));
	}

	validateToken(identityToken: { userId: any; token: string }) {
		return this.tokenService.post('v1/candidates/token/validate', JSON.stringify(identityToken));
	}

	resendToken(userId) {
		return this.tokenService.get('v1/candidates/' + userId + '/sendVerificationEmail');
	}
}
