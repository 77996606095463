import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-token-expiration-dialog',
	template: `
		<div class="pb-2">
			<mat-dialog-content>
				<div class="row">
					<div class="col-auto">
						<i class="fas fa-frown rmp-fs-4rem text-primary"></i>
					</div>
					<div class="col">
						<p class="rmp-fs-20px rmp-fw-regular rmp-gray-dark mb-3">{{ data.message }}</p>
						<p class="rmp-fs-12px rmp-fw-medium rmp-gray-light mb-0">
							Te rugăm să te autentifici din nou pentru a putea folosi platforma!
						</p>
					</div>
				</div>
			</mat-dialog-content>
			<mat-dialog-actions class="mt-2">
				<div class="w-100 text-right">
					<button mat-button [mat-dialog-close]="true" class="btn btn-primary rmp-button text-center">
						<span class="mx-41">Ok</span>
					</button>
				</div>
			</mat-dialog-actions>
		</div>
	`,
})
export class TokenExpirationDialogComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<TokenExpirationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit() {}
}
