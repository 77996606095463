import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable()
export class ImageHelper {
	constructor(private _sanitizer: DomSanitizer) {}

	getImage(image: any): SafeResourceUrl {
		if (image) {
			return this._sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64,${image}`);
		}
        return undefined;
	}
}
