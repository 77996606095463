import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { ApplicationFiltersModel } from '../models/applicationFilters.model';
import { LocalStorageEnum } from '../localStorage.enum';

@Injectable({
	providedIn: 'root',
})
export class ApplicationService {
	constructor(private tokenService: TokenService) {}

	deleteApplication(id) {
		return this.tokenService.delete('v1/applications/' + id);
	}

	withdrawApplication(applicationId) {
		return this.tokenService.put('v1/application/' + applicationId + '/withdraw', null);
	}

	submitApplication(applicationId: number) {
		return this.tokenService.put('v1/application/' + applicationId + '/submit', null);
	}

	countApplicationSuperagentView() {
		return this.tokenService.get('v1/applications/superagent/count');
	}

	exportApplications(numbers: number[]) {
		const jwt = localStorage.getItem(LocalStorageEnum.AUTH_TOKEN);
		const headers = new Headers();
		headers.append('Access-Control-Expose-Headers', 'authorization');
		headers.append('Authorization', jwt);
		const options = {
			responseType: 'blob',
			headers: headers,
		};

		let params = {
			ids: numbers,
			filters: null,
		};
		return this.tokenService.post('v1/applications/excel', params, options);
	}

	exportAllApplicationsExceptingApplicationIds(numbers: number[], filters: ApplicationFiltersModel) {
		const jwt = localStorage.getItem(LocalStorageEnum.AUTH_TOKEN);
		const headers = new Headers();
		headers.append('Access-Control-Expose-Headers', 'authorization');
		headers.append('Authorization', jwt);
		const options = {
			responseType: 'blob',
			headers: headers,
		};

		let params = {
			ids: numbers,
			filters: filters,
		};
		return this.tokenService.post('v1/applications/all/excel', params, options);
	}

	downloadDocument(documentId) {
		const jwt = localStorage.getItem(LocalStorageEnum.AUTH_TOKEN);
		const headers = new Headers({
			'Content-Type': 'application/json',
			Authorization: jwt,
		});

		const options = {
			responseType: 'blob',
			headers: headers,
		};

		return this.tokenService.get('v1/documents/' + documentId, options);
	}

	downloadAllDocuments(id) {
		const jwt = localStorage.getItem(LocalStorageEnum.AUTH_TOKEN);
		const headers = new Headers({
			'Content-Type': 'application/json',
			Authorization: jwt,
		});

		const options = {
			responseType: 'blob',
			headers: headers,
		};

		return this.tokenService.get('v1/applications/' + id + '/document/allFiles/', options);
	}

	downloadDocumentFile(fileId) {
		const jwt = localStorage.getItem(LocalStorageEnum.AUTH_TOKEN);
		const headers = new Headers({
			'Content-Type': 'application/json',
			Authorization: jwt,
		});

		const options = {
			responseType: 'blob',
			headers: headers,
		};

		return this.tokenService.get('v1/documents/files/' + fileId, options);
	}

	getApplicationFilterValues(filters: any) {
		return this.tokenService.post('v1/applications/filters', JSON.stringify(filters));
	}

	searchApplicationsByFilters(filters: ApplicationFiltersModel) {
		return this.tokenService.post('v1/applications/search', JSON.stringify(filters));
	}
	
	countAllApplicationsByFilters(filters: ApplicationFiltersModel) {
		return this.tokenService.post('v1/applications/search/all/count', JSON.stringify(filters));
	}

	setConditionalOffer(applicationId, applicationDTO) {
		return this.tokenService.put('v1/applications/' + applicationId + '/conditionalOffer', applicationDTO);
	}

	submitLeadApplications() {
		return this.tokenService.post('v1/applications/leads', null);
	}

	uploadRequestFile(formData: FormData) {
		const headers = {
			'Access-Control-Expose-Headers': 'authorization',
			'Content-Type': 'multipart/form-data',
		};
		return this.tokenService.post('v1/application/requestFile', formData, { headers: headers });
	}

	downloadRequestFile(applicationId: number) {
		const jwt = localStorage.getItem(LocalStorageEnum.AUTH_TOKEN);
		const headers = new Headers({
			'Content-Type': 'application/json',
			Authorization: jwt,
		});

		const options = {
			responseType: 'blob',
			headers: headers,
		};
		return this.tokenService.get('v1/application/' + applicationId + '/requestFile', options);
	}

	deleteRequestFile(applicationId: number) {
		return this.tokenService.delete('v1/application/' + applicationId + '/requestFile');
	}

	downloadGeneratedForm(id: number, formId: number) {
		const headers = new Headers({
			'Content-Type': 'application/json',
			Authorization: localStorage.getItem(LocalStorageEnum.AUTH_TOKEN),
		});

		const options = {
			responseType: 'blob',
			headers: headers,
		};
		return this.tokenService.get(`v1/applications/${id}/downloadForm/${formId}`, options);
	}
}
