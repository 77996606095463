import { TokenService } from './token.service';
import { ReferralModel } from '../models/referral.model';
import { Injectable } from '@angular/core';
import { ReferralFilterModel } from '../models/referralFilter.model';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ReferralService {
	paginationResultObserver: Subject<any> = new Subject<any>();

	constructor(private tokenService: TokenService) {}

	emitPaginationResult(paginationResult) {
		this.paginationResultObserver.next(paginationResult);
	}

	subscribePaginationResult() {
		return this.paginationResultObserver.asObservable();
	}

	createReferral(referral: ReferralModel) {
		return this.tokenService.post('v1/referrals', referral);
	}

	getReferrals() {
		return this.tokenService.get('v1/referrals');
	}

	searchReferrals(referralFilter: ReferralFilterModel) {
		return this.tokenService.post('v1/referrals/search', referralFilter);
	}

	updateReferral(referral: ReferralModel) {
		return this.tokenService.put('v1/referrals', referral);
	}

	deleteReferral(referralId: number) {
		return this.tokenService.delete('v1/referrals/' + referralId);
	}
}
