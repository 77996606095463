import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class PermissionsStorage {

	public data: any;

	public constructor() {
	}

}
