import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { shareReplay } from 'rxjs/operators';
import { SubjectArea } from '../models/subjectArea.model';

const CACHE_SIZE = 1;

@Injectable({
	providedIn: 'root',
})
export class SubjectAreaService {
	private cache$: Map<number, any> = new Map<number, any>();

	constructor(private tokenService: TokenService) {}

	getSubjectAreaImageById(id) {
		if (!this.cache$.has(id)) {
			this.cache$.set(
				id,
				this.tokenService.get('v1/subjectAreas/' + id + '/image').pipe(shareReplay(CACHE_SIZE))
			);
		}
		return this.cache$.get(id);
	}

	setSubjectAreaImage(id: number, formData: FormData) {
		const headers = {
			'Access-Control-Expose-Headers': 'authorization',
			'Content-Type': 'multipart/form-data',
		};
		return this.tokenService.post('v1/subjectAreas/' + id + '/image', formData, { headers: headers });
	}

	getSubjectAreas(availableCoursesOnly?) {
		const params = { availableCoursesOnly };
		return this.tokenService.get('v1/subjectAreas', { params: params });
	}

	getAllGroupedByLetters() {
		return this.tokenService.get('v1/subjectAreas/groupByFirstLetter');
	}

	deleteSubjectArea(id: number) {
		return this.tokenService.delete('v1/subjectAreas/' + id);
	}

	createSubjectArea(subjectArea) {
		return this.tokenService.post('v1/subjectAreas', JSON.stringify(subjectArea));
	}

	reorderSubjectAreas(subjectAreas: SubjectArea[]) {
		return this.tokenService.post('v1/subjectAreas/reorder', JSON.stringify(subjectAreas));
	}

	updateSubjectArea(subjectArea) {
		return this.tokenService.put('v1/subjectAreas', JSON.stringify(subjectArea));
	}
}
