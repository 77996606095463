import { Townhall } from './models/townhall.model';
import { UserRole } from './models/user-role.enum';
import { UserAfterLoginRoute } from './models/userState.model';

export class Globals {
	public static API_ENDPOINT = '';
	public static LOGIN_PATH = 'v1/sign-in';
	public static LOGIN_CLERK_PATH = 'v1/sign-in/clerk'
	public static REGISTER_PATH = 'v1/sign-up';

	public static FIND_ADDRESS_API_ENDPOINT = 'https://api.getAddress.io/find/';
	public static FIND_ADDRESS_API_KEY = 'XoYW8zUpnk-ceL6ZmLdsEw14987';
	// Check when creating bundles
	//TO DO
	//public static APPLICATION_URL = 'http://localhost:4200';
	public static APPLICATION_URL = typeof window !== 'undefined' ? 'https://' + window.location.hostname : "http://localhost:4200";

	public static HOME_LINKS: any[] = [
		{ path: '/acasa', name: 'Acasă' },
		{ path: '/servicii', name: 'Servicii' },
		{ path: '/intrebari-frecvente', name: 'Întrebări frecvente' },
		{ path: '/contact', name: 'Contact' },
		{ path: '/stiri', name: 'Știri și evenimente' },
	];

	public static SUPERAGENT_LINKS: any[] = [
		{ path: '/superagent/tablou-de-bord', name: 'Tablou de bord' },
		{ path: '/superagent/cereri', name: 'Cereri' },
		{ path: '/superagent/sarcini', name: 'Sarcini' },
		{ path: '/superagent/inregistrari', name: 'Registratură' },
		{ path: '/superagent/servicii', name: 'Servicii' },
		{ path: '/superagent/admin', name: 'Admin' },
		{ path: '/superagent/mesaje', name: 'Mesaje' },
		{ path: '/superagent/utilizatori', name: 'Utilizatori' },
	];

	public static CLERK_LINKS: any[] = [
		{ path: '/functionar/tablou-de-bord', name: 'Tablou de bord' },
		{ path: '/functionar/cereri', name: 'Cereri' },
		{ path: '/functionar/sarcini', name: 'Sarcini' },
		{ path: '/functionar/inregistrari', name: 'Registratură' },
		{ path: '/functionar/utilizatori', name: 'Utilizatori' },
		{ path: '/functionar/mesaje', name: 'Mesaje' },
	];

	public static CANDIDATE_LINKS: any[] = [
		{ path: '/candidate/servicii', name: 'Servicii' },
		{ path: '/candidate/cererile-mele', name: 'Cererile mele' },
		// { path: '/candidate/serviciile-mele', name: 'Serviciile mele' },
	];

	public static CANDIDATE_PROFILE_LINKS: any[] = [{ path: '/candidate/editProfile', name: 'Editare profil' }];

	public static isLoggedIn;
	public static loggedInUserRole: UserRole;
	public static loggedInUserRoute: UserAfterLoginRoute;

	public static EMAIL_PATTERN =
		'(?:[A-Za-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])';
	public static PASSWORD_PATTERN = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$';

	public static CNP_PATTERN = '(^[1256])([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([0-9]{6})';

	// Routing after login
	// SUPERAGENT
	public static SUPERAGENT_LOGIN_ROUTE = '/superagent/tablou-de-bord';
	// FUNCTIONAR
	public static CLERK_LOGIN_ROUTE = '/functionar/tablou-de-bord';

	// CANDIDATE
	public static CANDIDATE_COURSES_ROUTE = '/candidate/servicii';
	public static CANDIDATE_APPLICATIONS_ROUTE = '/candidate/cererile-mele';
	public static CANDIDATE_CHANGE_PASSWORD_ROUTE = '/candidate/greetings/change-password';
	public static CANDIDATE_PROFILE_ROUTE = '/candidate/greetings/profile';
	public static CANDIDATE_CONTINUE_APPLICATION_ROUTE = '/candidate/cerere/';

	// Mass communication generic routing
	public static CREATE_MESSAGE_GENERIC_ROUTE = '/mesaje/creeaza';
	public static VIEW_MESSAGES_GENERIC_ROUTE = '/mesaje/vizualizeaza-mesajele';
	public static COMPOSE_MESSAGE_GENERIC_ROUTE = '/mesaje/compune';
	public static VIEW_MESSAGE_GENERIC_ROUTE = '/mesaje/vizualizeaza-mesajul';

	// File content types allowed
	public static FILE_CONTENT_TYPES_ALLOWED = [
		'image/gif',
		'image/png',
		'image/jpeg',
		'image/bmp',
		'image/webp',
		'image/vnd.microsoft.icon',
		'application/pdf',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/msword',
	];

	public static REFERRAL_BUTTON_HTML =
		'<a style="display: inline-block;text-align: center;border: 1px solid transparent;font-size: 1rem;' +
		'color: #fff;background-color: #2E77CC;border-color: #2E77CC;line-height: 40px !important;border-radius: none;' +
		'padding-top: 0 !important;padding-bottom: 0 !important;font-weight: 500;width: 150px !important;cursor: pointer;' +
		'text-decoration: none;font-family: sans-serif;"href="$referralLink">Apply</button>';

	public static SHARE_DESCRIPTION_DEFAULT = 'Empowering Careers through Education';

	public static TASK_PREFIX = 'SM-';

	public static FACEBOOK_APP_ID = '243242019679453';

	public static TOWNHALL_DETAILS: Townhall;
}
