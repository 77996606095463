import { Inject, Injectable, PLATFORM_ID, afterNextRender, afterRender } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { LocalStorageEnum } from '../localStorage.enum';
import { UserRole } from '../models/user-role.enum';
import { UserAfterLoginRoute } from '../models/userState.model';
import { AuthData, TokenServiceConfig } from './token.model';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { UserInfo } from '../models/userInfo.model';
import { isPlatformBrowser } from '@angular/common';

const defaultConfig: TokenServiceConfig = {
	apiPath: 'api',
	apiBase: Globals.API_ENDPOINT,
	loginPath: 'v1/sign-in',
	registerPath: 'v1/sign-up',

	globalConfig: {
		headers: {
			'Access-Control-Expose-Headers': 'authorization, user_id',
			'Content-Type': 'application/json',
		},
	},
};

@Injectable({
	providedIn: 'root',
})
export class TokenService {
	private atConfig: TokenServiceConfig;
	private atCurrentAuthData: AuthData;
	private router: Router;
	isBrowser: boolean;
	logged = new Subject<boolean>();
	get currentAuthData(): AuthData {
		return this.atCurrentAuthData;
	}

	constructor(private http: HttpClient, router: Router, @Inject(PLATFORM_ID) private platformId: Object) {
		this.init();
		this.router = router;
	}

	init(options?: TokenServiceConfig) {
		this.atConfig = (<any>Object).assign(defaultConfig, options);
		this.tryLoadAuthData();
	}

	get(url: string, options?: any): Observable<any> {
		url = this.getURL(url);
		options = this.getRequestOptions(options);
		return this.http.request('GET', url, options);
	}

	post(url: string, body: any, options?: any): Observable<any> {
		url = this.getURL(url);
		options = this.getRequestOptions(options);
		options.body = body;
		return this.http.request('POST', url, options);
	}

	put(url: string, body?: any, options?: any): Observable<any> {
		url = this.getURL(url);
		options = this.getRequestOptions(options);
		options.body = body;
		return this.http.request('PUT', url, options);
	}

	delete(url: string, options?: any): Observable<any> {
		url = this.getURL(url);
		options = this.getRequestOptions(options);
		return this.http.request('DELETE', url, options);
	}

	patch(url: string, body: any, options?: any): Observable<any> {
		url = this.getURL(url);
		options = this.getRequestOptions(options);
		options.body = body;
		return this.http.request('PATCH', url, options);
	}

	isLoggedIn(isLoggedIn: boolean) {
		this.logged.next(isLoggedIn);
	}

	setUserInfoAuthData(userInfo?: UserInfo) {
		const authData: AuthData = {
			role: userInfo.role,
			user_id: userInfo.id?.toString(),
			user_route: userInfo.afterLoginRoute,
			signature_reminder: userInfo.signatureReminder,
		};
		Globals.isLoggedIn = true;
		Globals.loggedInUserRole = UserRole[userInfo.role];
		Globals.loggedInUserRoute = UserAfterLoginRoute[authData.user_route];
		this.setAuthData(authData);
		this.routeAfterLogin(authData);
	}

	private getURL(url: string): string {
		return Globals.API_ENDPOINT + '/api/' + url;
	}

	private getRequestOptions(options) {
		options = options ? { ...this.cleanParams(options), observe: 'response' } : { observe: 'response' };
		if (isPlatformBrowser(this.platformId) && localStorage.getItem('authorization')) {
			const auth = localStorage.getItem('authorization');
			options.headers = options.headers ? { ...options.headers, Authorization: auth } : { Authorization: auth };
		}
		if (!options.headers) {
			options.headers = {};
		}
		if (options.headers['Content-Type'] === 'multipart/form-data') {
			delete options.headers['Content-Type'];
		} else {
			options.headers['Content-Type'] = 'application/json';
		}

		return options;
	}

	private cleanParams(options?): any {
		if (options && options.params) {
			const cleanedParams = {};
			Object.entries(options.params).forEach(([key, value]) => {
				if (value !== undefined && value !== null) {
					cleanedParams[key] = value;
				}
			});
			options.params = cleanedParams;
		}
		return options;
	}

	private getAuthDataFromStorage(): void {
		let authorization = null;
		let role = null;
		let user_id = null;
		let user_route = null;
		if (isPlatformBrowser(this.platformId)) {
			authorization = localStorage.getItem(LocalStorageEnum.AUTH_TOKEN);
			role = localStorage.getItem(LocalStorageEnum.LOGGED_IN_USER_ROLE);
			user_id = localStorage.getItem(LocalStorageEnum.LOGGED_IN_USER_ID);
			user_route = localStorage.getItem(LocalStorageEnum.USER_AFTER_LOGIN_ROUTE);
		}
		const authData: AuthData = {
			authorization,
			role,
			user_id,
			user_route,
		};

		if (authorization !== null) {
			Globals.isLoggedIn = true;
			Globals.loggedInUserRole = UserRole[authData.role];
		}
		this.atCurrentAuthData = authData;
	}

	private routeAfterLogin(authData: AuthData) {
		switch (authData.role) {
			case UserRole.SUPERAGENT: {
				this.router.navigate([Globals.SUPERAGENT_LOGIN_ROUTE]);
				break;
			}
			case UserRole.CLERK: {
				this.router.navigate([Globals.CLERK_LOGIN_ROUTE]);
				break;
			}
			case UserRole.CANDIDATE: {
				this.get('v1/users/current/afterLoginRoute').subscribe((success) => {
					const afterLoginRoute = success.afterLoginRoute;
					if (afterLoginRoute) {
						localStorage.setItem(LocalStorageEnum.USER_AFTER_LOGIN_ROUTE, afterLoginRoute.toString());
					}

					switch (afterLoginRoute) {
						case UserAfterLoginRoute.PASSWORD: {
							this.router.navigate([Globals.CANDIDATE_CHANGE_PASSWORD_ROUTE]);
							break;
						}
						case UserAfterLoginRoute.PROFILE: {
							this.router.navigate([Globals.CANDIDATE_PROFILE_ROUTE]);
							break;
						}
						case UserAfterLoginRoute.COURSES: {
							const courseAppliedId = localStorage.getItem(
								LocalStorageEnum.APPLICATION_NOT_LOGGED_IN_COURSE_ID
							);
							if (courseAppliedId) {
								this.router.navigate([Globals.CANDIDATE_CONTINUE_APPLICATION_ROUTE, courseAppliedId]);
							} else {
								this.router.navigate([Globals.CANDIDATE_COURSES_ROUTE]);
							}
							break;
						}
						case UserAfterLoginRoute.APPLICATIONS: {
							const courseAppliedId = localStorage.getItem(
								LocalStorageEnum.APPLICATION_NOT_LOGGED_IN_COURSE_ID
							);
							if (courseAppliedId) {
								this.router.navigate([Globals.CANDIDATE_CONTINUE_APPLICATION_ROUTE, courseAppliedId]);
							} else {
								this.router.navigate([Globals.CANDIDATE_APPLICATIONS_ROUTE]);
							}
							break;
						}
						default: {
							this.router.navigate([Globals.CANDIDATE_COURSES_ROUTE]);
							break;
						}
					}
				});
				break;
			}
		}
	}

	public updateToken(headers: any) {
		const token = headers?.get(LocalStorageEnum.AUTH_TOKEN);
		localStorage.setItem(LocalStorageEnum.AUTH_TOKEN, token);

		const expiresAt = headers?.get(LocalStorageEnum.TOKEN_EXPIRES_AT);
		if (expiresAt) {
			localStorage.setItem(LocalStorageEnum.TOKEN_EXPIRES_AT, expiresAt);
		}

		const refreshToken = headers?.get(LocalStorageEnum.REFRESH_TOKEN);
		if (refreshToken) {
			localStorage.setItem(LocalStorageEnum.REFRESH_TOKEN, refreshToken);
		}

		this.atCurrentAuthData = { ...this.atCurrentAuthData, authorization: token };
	}

	private setAuthData(authData: AuthData): void {
		localStorage.setItem(LocalStorageEnum.LOGGED_IN_USER_ROLE, authData.role);
		localStorage.setItem(LocalStorageEnum.LOGGED_IN_USER_ID, authData.user_id);
		localStorage.setItem(LocalStorageEnum.USER_AFTER_LOGIN_ROUTE, authData.user_route);

		if (!localStorage.getItem(LocalStorageEnum.SIGNATURE_REMINDER)) {
			localStorage.setItem(LocalStorageEnum.SIGNATURE_REMINDER, authData.signature_reminder.toString());
		}
		this.atCurrentAuthData = { ...this.atCurrentAuthData, ...authData };
	}

	private tryLoadAuthData(): void {
		this.getAuthDataFromStorage();
	}
}
