export enum LocalStorageEnum {
	USER_AFTER_LOGIN_ROUTE = 'user_route',
	APPLICATION_NOT_LOGGED_IN_COURSE_ID = 'courseAppliedId',
	LOGGED_IN_USER_ID = 'id',
	LOGGED_IN_USER_ROLE = 'role',
	AUTH_TOKEN = 'authorization',
	TOKEN_EXPIRES_AT ="id_token_expires_at",
	ID_TOKEN = 'id_token',
	REFRESH_TOKEN = 'refresh_token',
	SIGNATURE_REMINDER = 'signature_reminder'
}
