import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FaqModel } from '../../../../../shared/models/faq.model';
import { FaqService } from '../../../../../shared/services/faq.service';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../../shared/services/alert.service';
@Component({
	selector: 'app-faq-admin',
	templateUrl: './faq-admin.component.html',
	styleUrls: ['./faq-admin.component.scss'],
})
export class FaqAdminComponent implements OnInit {
	faqFormGroup: any;
	isCollapsed = true;
	faqId: any;
	faqs: FaqModel[];
	faqIndex: any;
	addExtra = false;
	closeResult: string;
	modalReference: any;
	@ViewChild('addFaqModal')
	faqModalElement: TemplateRef<any>;
	isEdit = false;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private faqService: FaqService,
		private modalService: NgbModal,
		private alertService: AlertService
	) {
		this.faqFormGroup = this.formBuilder.group({
			question: ['', Validators.required],
			answer: ['', Validators.required],
			faqId: [],
		});
	}

	ngOnInit() {
		this.faqService.getFaqs().subscribe((success) => {
			this.faqs = success.body;
		});
	}

	edit(faq, index) {
		const { question, answer, faqId } = faq;
		this.faqFormGroup.patchValue({ question, answer, faqId });
		this.faqId = faq.faqId;
		this.faqIndex = index;
		this.openModal(this.faqModalElement, true);
	}

	emptyForm() {
		this.isCollapsed = true;
		this.faqFormGroup.reset();
		if (!this.addExtra) {
			this.closeModal();
		}
	}

	openModal(content, isEdit) {
		if (isEdit) {
			this.isEdit = true;
		}
		const ngbModalOptions: NgbModalOptions = {
			backdrop: 'static',
			keyboard: false,
			centered: true,
			size: 'lg',
		};
		this.modalReference = this.modalService.open(content, ngbModalOptions);
		this.modalReference.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	closeModal() {
		this.modalReference.close();
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	toggleCheckboxAddExtra(event) {
		this.addExtra = !!event.target.checked;
	}

	submitFaq() {
		if (this.faqFormGroup.valid) {
			if (this.faqId) {
				this.faqService.editFaq(this.faqFormGroup.value).subscribe(
					(success) => {
						this.faqs[this.faqIndex] = this.faqFormGroup.value;
						this.emptyForm();
					},
					(error) => this.alertService.error(error.error.message)
				);
			} else {
				this.faqService.addFaq(this.faqFormGroup.value).subscribe(
					(success) => {
						this.faqs.push(success.body);
						this.emptyForm();
					},
					(error) => this.alertService.error(error.error.message)
				);
			}
			this.faqId = null;
		} else {
			Object.keys(this.faqFormGroup.controls).forEach((field) => {
				const control = this.faqFormGroup.get(field);
				control.markAsTouched({ onlySelf: true });
			});
		}
	}

	deleteFaq(faqId, index) {
		this.faqService.deleteFaq(faqId).subscribe((success) => {
			this.faqs.splice(index, 1);
		});
	}

	isFieldRequired(field: string) {
		if (this.faqFormGroup.get(field).hasError('required') && this.faqFormGroup.get(field).touched) {
			return this.faqFormGroup.get(field).hasError('required') && this.faqFormGroup.get(field).touched;
		}
	}
}
