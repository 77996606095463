import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { NotificationReadModel } from '../models/notificationRead.model';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	constructor(private tokenService: TokenService) {}

	getNotificationsByUserId() {
		return this.tokenService.get('v1/notifications');
	}

	getLatest5NotificationsByUserId() {
		return this.tokenService.get('v1/notifications/latest');
	}

	countNewNotificationsByUserId() {
		return this.tokenService.get('v1/notifications/unread/count');
	}

	markRead(notificationId: number, read: boolean) {
		const params = { read: read };
		return this.tokenService.put('v1/notifications/' + notificationId + '/read', null, { params: params });
	}

	markAllAsRead(notificationRead: NotificationReadModel) {
		return this.tokenService.post('v1/notifications/read', JSON.stringify(notificationRead));
	}

	deleteOne(id: number) {
		return this.tokenService.delete('v1/notifications/' + id);
	}

	deleteAll(userId) {
		const params = {
			userId: userId,
		};
		return this.tokenService.delete('v1/notifications', { params: params });
	}
}
